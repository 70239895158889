import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取评论管理员列表
 * @returns
 */
export const commentsysList = ({ ...params }) => {
  let { pageNo, pageSize, ...param } = params
  return awaitWrap(
    request({
      url: `/comment/user/list/${pageNo}/${pageSize}`,
      method: 'get',
      params: param
    })
  )
}

/**
 * 指定用户为评论管理者
 * @param {*} "usersId" 用户id
 * @returns
 */
export const commentsysSet = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/comment/user/set`,
      method: 'POST',
      data
    })
  )
}

/**
 * 搜索特定用户
 * @returns
 */
export const commentUserSearch = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/comment/user/search`,
      method: 'get',
      params: params
    })
  )
}