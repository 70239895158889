<template>
  <el-dialog title="添加评论管理员" :visible.sync="dialogVisible" width="550px" height="28%" @close="close">
    <el-row :gutter="10">
      <el-col :span="16">
        <el-input placeholder="请输入用户ID/昵称/手机号" v-model="params.name" size="small" @change="change" clearable></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="handlesearch()" size="small">搜索</el-button>
      </el-col>
    </el-row>
    <div style="margin:20px 0">
      <div v-if="list.length>0" class="userList-div">
        <el-row>
          <el-col :span="12" v-for="(item, index) in list" :key="index">
            <div @click="onUserSelect(item)" :class="'my-flex my-flex__item-vcenter pinter user-item' + (item.userId==selectedUser.userId?' active':'')">
              <div>
                <img :src=" item.img || $store.state.Defaultimg.defaultimg"
                  alt height="50px" width="50px" />
              </div>
              <div class="my-flex__item font13 color9 userInfo margin-left10">
                <p>昵称：<span class="color6">{{item.nickName}}</span></p>
                <p>ID：<span class="color6">{{item.userId}}</span></p>
                <p>手机号：<span class="color6">{{item.phone}}</span></p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-else class="my-flex my-flex__item-vcenter user-wait">
        <div>
          <img :src="$store.state.Defaultimg.defaultimg"
            alt height="50px" width="50px" />
        </div>
        <div class="my-flex__item font13 color9 userInfo margin-left10">
          <p>昵称：等待搜索</p>
          <p>ID：等待搜索</p>
          <p>手机号：等待搜索</p>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer margin-right-10">
      <span>
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
import { commentUserSearch } from '@/api/CommentController'
export default {
  name: 'addDialog',
  props: {
    isshowDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      list: [],

      params: {
        name: '',
      },

      dialogVisible: false,
      selectedUser: {}
    }
  },
  methods: {
    change(v) {
      !v && (this.list=[])
    },

    async handlesearch() {
      console.log(this.params)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let [err, res] = await commentUserSearch(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      console.log(res)

      this.list = res.data || []
      if(this.list.length>0){
        this.selectedUser = this.list[0]
      }
      loading.close()

      if(this.list.length==0){
        this.$message.warning('没有搜索到用户')
      }
    },

    onUserSelect(item){
      this.selectedUser = item
    },
    save() {
      if(this.selectedUser.userId){
        this.$emit('save', this.selectedUser.userId)
      }else {
        this.$message.warning('请选择一个用户')
      }
    },
    close() {
      this.$emit('close')
    },
  },

  watch: {
    isshowDialog: function () {
        this.dialogVisible = this.isshowDialog;
    }
  },
}
</script>
<style lang="less" scoped>
.userInfo p{
  line-height: 22px;
}
.userList-div{
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}
.user-item, .user-wait {
  padding: 2px 10px 2px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 1px solid #fff;
}
.user-item.active, .user-item:hover{
  border: 1px solid #409EFF;
}
</style>