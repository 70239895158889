<template>
  <div>
    <TitleBarBaseVue  />
    <el-row :gutter="20">
      <el-col :span="3">
        <el-input v-model="params.id" clearable placeholder="请输入用户ID" @change="change" />
      </el-col>
      <el-col :span="4">
        <el-input v-model="params.name" clearable placeholder="请输入用户昵称" @change="change" />
      </el-col>
      <el-col :span="15">
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="changeDate"
        >
        </el-date-picker>
        <el-button type="primary" class="margin-left20" @click="handlesearch()">搜索</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="danger" @click="handleAdd()">添加</el-button>
      </el-col>
    </el-row>
    <el-table :data.sync="tableList" border>
      <el-table-column
        prop="userId"
        label-class-name="header"
        label="用户ID"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column prop="img" label="用户头像" min-width="80" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.img" alt style="width:40px;height:40px;" />
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="150" align="center"></el-table-column>
      <el-table-column prop="wxOpenid" label="openID" min-width="200" align="center"></el-table-column>
      <el-table-column prop="createTime" label="入驻时间" min-width="200" align="center">
        <template slot-scope="scope">{{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
    </el-table>
    <addDialog :isshowDialog="isshowDialog" @save="save" @close="close" />
  </div>
</template>
<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { commentsysList, commentsysSet } from '@/api/CommentController'
import addDialog from './component/addDialog'
export default {
  name: 'commentsys',
  components: {
    TitleBarBaseVue,
    addDialog,
  },
  inject: ['reload'],
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ],
      },
      params: {
        pageNo: 1,
        pageSize: 10,
        id: '',
        name: '',
        startTime: '',
        endTime: '',
      },
      total: '',
      tableList: [],
      isshowDialog: false,
      UserInfo: [],

      date: '',
    }
  },
  mounted() {
    this.getcommentsysListReq()
  },
  methods: {
    async getcommentsysListReq() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let { id, name, startTime, endTime, ...Newparams } = this.params
      id && (Newparams['id'] = id)
      name && (Newparams['name'] = name)
      startTime && (Newparams['startTime'] = startTime)
      endTime && (Newparams['endTime'] = endTime)
      let [err, res] = await commentsysList(Newparams)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      console.log(res)
      // this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
      loading.close()
    },

    change(v) {
      !v && this.handlesearch()
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handlesearch()
      }
    },

    handlesearch() {
      console.log(this.params)
      this.getcommentsysListReq()
    },

    handleAdd() {
      this.isshowDialog = true
    },
    async save(data) {
      let { ...ss } = {}
      ss.userId = data
      let [err, res] = await commentsysSet(ss)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '添加失败')
      } else {
        this.isshowDialog = false
        this.getcommentsysListReq()
      }
    },
    close() {
      this.isshowDialog = false
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .el-date-editor.el-input {
  width: 100%;
}
.el-table {
  margin-top: 20px;
}
</style>